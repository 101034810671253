export enum SegmentEvent {
    COMMUNITY_HOME = 'community:home',
    COMMUNITY_LANDING_LECLUB_CANDIDATE = 'community:home:btn_leclub_candidate',
    COMMUNITY_LANDING_LEFONDS_INVESTOR_LINK = 'community:home:btn_lefonds_jinvestis',
    COMMUNITY_LANDING_LEFONDS_PROJECT_LINK = 'community:home:btn_lefonds_jeleve',
    COMMUNITY_LANDING_BUSINESSSOLUTIONS_DISCOVER = 'community:home:btn_businessolutions_jedecouvre',
    COMMUNITY_RESOURCES_REGISTER = 'community:ressources:btn_sinscrire',
    COMMUNITY_RESOURCES_HOME = 'community:ressources:home',
    COMMUNITY_RESOURCES_PODCAST = 'community:ressources:podcast',
    COMMUNITY_RESOURCES_ARTICLE = 'community:ressources:article',
    COMMUNITY_RESOURCES_VIDEO = 'community:ressources:video',
    COMMUNITY_EVENT_GO = 'community:event:participer',
    COMMUNITY_EVENT_HOME = 'community:event:home',
    COMMUNITY_JOBS_HOME = 'community:jobs:home',
    COMMUNITY_JOBS_SEARCH = 'community:jobs:search',
    COMMUNITY_JOBS_CONTACT_RECRUTER = 'community:jobs:contact_recruter',
    COMMUNITY_JOBS_SUBMIT_OFFER = 'community:jobs:submit_offer',
    COMMUNITY_JOBS_INTERESTED = 'community:jobs:interested',
    COMMUNITY_JOBS_APPLIED = 'community:jobs:applied',
    COMMUNITY_ONBOARDING_PROFILE_FILLED = 'community:onboarding:profile_filled',
    COMMUNITY_ONBOARDING_JOB_AVAILABILITY_FILLED = 'community:onboarding:job_availability_filled',
    COMMUNITY_ONBOARDING_JOB_AVAILABILITY_SKIPPED = 'community:onboarding:job_availability_skipped',
    COMMUNITY_ONBOARDING_JOB_FILLED = 'community:onboarding:job_filled',
    COMMUNITY_ONBOARDING_JOB_SKIPPED = 'community:onboarding:job_skipped',
    COMMUNITY_ONBOARDING_GOALS_FILLED = 'community:onboarding:goals_filled',
    COMMUNITY_ONBOARDING_GOALS_SKIPPED = 'community:onboarding:goals_skipped',
    COMMUNITY_ONBOARDING_LOCATION_FILLED = 'community:onboarding:location_filled',
    COMMUNITY_ONBOARDING_LOCATION_SKIPPED = 'community:onboarding:location_skipped',
    COMMUNITY_ONBOARDING_GEO_FILLED = 'community:onboarding:geo_filled',
    COMMUNITY_ONBOARDING_GEO_SKIPPED = 'community:onboarding:geo_skipped',
    COMMUNITY_ONBOARDING_PICTURE_FILLED = 'community:onboarding:picture_filled',
    COMMUNITY_ONBOARDING_PICTURE_SKIPPED = 'community:onboarding:picture_skipped',
    COMMUNITY_ONBOARDING_COMPLETE = 'community:onboarding:complete',
    COMMUNITY_GROUPS_INVITE_MODAL_OPENED = 'community:groups:invite_modal_opened',
    COMMUNITY_GROUPS_INVITE_SENT = 'community:groups:invite_sent',
    LECLUB_REFER_BANNER = 'leclub:refer:banner',
    EVENT_BOOK_TICKET = 'event:book-ticket',
    LECLUB_AGREEMENT = 'leclub:agreement:algo',
    EVENT_TICKETING_DISCOUNT_APPLIED = 'global:events:ticketing:discount_applied',
    EVENT_TICKETING_INFORMATION_CONFIRMED = 'global:events:ticketing:informations_confirmed_CTA',
    EVENT_TICKETING_PAID = 'global:events:ticketing:paid_CTA',
    EVENT_TICKETING_CONTACT_US = 'global:events:ticketing:contact_us',
    EVENT_TICKETING_ORDERED_TICKET = 'global:events:ticketing:ordered_CTA',
    LECLUB_MER_MESSAGE_IA_CORRECTED = 'leclub:mer:message_ia_corrected',
    LECLUB_MER_MESSAGE_UNDO_IA_CORRECTED = 'leclub:mer:message_undo_ia_corrected',
    LECLUB_MER_MESSAGE_IA_REFACTOR = 'leclub:mer:message_ia_refactored',
    LECLUB_MER_MESSAGE_UNDO_IA_RECATOR = 'leclub:mer:message_undo_ia_refactored',
    LECLUB_OFFBOARDING_FEEDBACK_SELECTED = 'club:offboarding:feedback:selected',
    LECLUB_OFFBOARDING_DETAILS_CONTINUED = 'leclub:offboarding:detail:continued',
    LECLUB_OFFBOARDING_OFFER_DOWNGRADE = 'leclub:offboarding:offers:membership_downgraded',
    LECLUB_OFFBOARDING_OFFER_PAUSE = 'leclub:offboarding:offers:membership_paused',
    LECLUB_OFFBOARDING_OFFER_SKIPPED = 'leclub:offboarding:offers:skipped',
    LECLUB_OFFBOARDING_MEMBERSHIP_CANCEL = 'leclub:offboarding:membership_canceled',
    LECLUB_HOMEPAGE_POLL = 'leclub:homepage:NPS_clicked',
    LECLUB_HOMEPAGE_RELATIONSHIP_SKIPPED = 'leclub:homepage:actions:relationship_skipped',
    LECLUB_RELATIONSHIP_SUGGESTION_ACCEPTED = 'leclub:relationship_suggestions:accepted',
    LECLUB_RELATIONSHIP_SUGGESTION_REFUSED = 'leclub:relationship_suggestions:refused',
    LECLUB_HOMEPAGE_RELATIONSHIP_CONTACTED = 'leclub:homepage:actions:relationship_contacted',
    LECLUB_HOMEPAGE_EVENT_INVITATION_ACCEPTED = 'leclub:homepage:actions:events:invitation_accepted',
    LECLUB_HOMEPAGE_EVENT_INVITATION_REFUSED = 'leclub:homepage:actions:events:invitation_refused',
    LECLUB_HOMEPAGE_SUGGESTIONS_CLICKED = 'leclub:homepage:suggestions:clicked',
    LECLUB_HOMEPAGE_RM_CONTACTED = 'leclub:homepage:rm:contacted',
    LECLUB_HOMEPAGE_RM_CALENDAR = 'leclub:homepage:rm:rdv_scheduled',
    LECLUB_HOMEPAGE_PROFILE_CLICKED = 'leclub:homepage:profile_clicked',
    LECLUB_HOMEPAGE_NEXT_EVENT_EMPTY = 'leclub:homepage:next_event:empty_clicked',
    LECLUB_HOMEPAGE_NEXT_TRIP_EMPTY = 'leclub:homepage:next_trip_planner:empty_clicked',
    LECLUB_PAYWALL_MEMBER = 'leclub:paywall:member'
}
