import { TeamId } from '@/types/API.type'
import { RouteContext } from '@/types/Router.type'
import { BusinessLineName, RoleName } from '@/vars/AuthAttr'
import { CompaniesRouteName, ProRouteName, RouteName, AboutRouteName } from '@/vars/RouteName'
import { SpaName } from '@/vars/SpaAttr'
import TeamApi from '../api/Team.api'
import { useAuthStore } from '../stores/auth.store'
import LocalStorage from '../core/LocalStorage'
import { useTeamStore } from '../stores/team.store'
import { TeamRole } from '@/types/TeamRole.type'
import i18n from '@/i18n'
import useNotification from '../compositions/useNotification'
import { ToastType } from '@/vars/ToastAttr'
import { APPLY_INVITE_CODE, PRO_NEED_CREATE_TEAM } from '@/vars/ApplyAttr'
import { RouteLocation } from 'vue-router'
import { TeamFeature } from '@/vars/TeamFeatures.type'
import MemberApi from '../api/Member.api'
import { Component as ComponentType } from 'vue'

const { toast } = useNotification()

/**
 * @debt quality "Diviser les middleware entre ceux qui correspondent bien à l'authentification et ceux lié à autre chose. (ex: onboarding)"
 */

export async function getAuthentifiedUser({ to, next, router }: RouteContext) {
    const store = useAuthStore()
    try {
        if (to.query.refresh) {
            router.push({ name: getRouteHomeName() })
        }

        await store.getMe()
    } catch (e) {
        console.error(e)
    } finally {
        document.body.classList.add('loaded')
    }

    return next()
}

export async function checkTokenValidity({ to, next }: RouteContext) {
    const store = useAuthStore()

    if (store.decodedToken && store.decodedToken.exp && new Date(store.decodedToken.exp * 1000) < new Date()) {
        // token is expired
        if (store.authRefreshToken) {
            try {
                await store.refreshToken()
            } catch {
                return store.login(`${window.location.origin}${to.fullPath}`)
            }
        } else {
            return store.login(`${window.location.origin}${to.fullPath}`)
        }
    }

    return next()
}

function getRouteHomeName() {
    switch (import.meta.env.VITE_SPA_RUNNING) {
        case SpaName.LENETWORK:
            return RouteName.HOME
        case SpaName.PRO:
            return ProRouteName.HOME
        default:
            throw new Error(`${import.meta.env.VITE_SPA_RUNNING} is not a valid value`)
    }
}

export async function requireProOnboarding({ next, to }: RouteContext) {
    const teamStore = useTeamStore()

    if (!teamStore.myTeam?.company?.isOnboardingFinished) {
        next({ ...to, name: ProRouteName.ONBOARDING })
        return
    }

    return next()
}

export async function requireTalentOnboarding({ next, router }: RouteContext) {
    const store = useAuthStore()

    if (store.isLoggedIn && !store.authentifiedUser?.finishedOnboarding?.talent) {
        router.push({ name: RouteName.TALENT_ONBOARDING })
    }

    return next()
}

export async function requireTeam({ next, to, router }: RouteContext) {
    const store = useAuthStore()
    const teamStore = useTeamStore()

    if (!store.decodedToken) {
        console.error('No decoded token found')
        return false
    }

    let myTeamId = store.decodedToken.team?.id || ''

    if (!myTeamId) {
        try {
            const { data: teams } = await TeamApi.getMyTeams()

            if (teams?.[0]?.id) {
                myTeamId = teams[0].id
            }

            const { data, meta, status } = teams.length
                ? await TeamApi.switchToTeam(myTeamId)
                : await TeamApi.createNewTeam()

            if (meta) {
                store.updateTokens(meta.jwt.token, meta.jwt.refreshToken)
                if (!myTeamId) {
                    myTeamId = (data as TeamId).id
                }
            }

            if (status === 204) {
                LocalStorage.set(PRO_NEED_CREATE_TEAM, true)
                if (to.name === ProRouteName.ONBOARDING || to.name === ProRouteName.ONBOARDING) {
                    return next()
                } else {
                    router.push({ name: ProRouteName.ONBOARDING })
                }
            }
        } catch {
            router.push({ name: ProRouteName.TEAM_ALREADY_EXISTING })
            return false
        }
    }
    if (!teamStore.myTeam) {
        const { data: myTeam } = await TeamApi.getTeam(myTeamId)
        teamStore.$patch({ myTeam })
    }

    return next()
}

export async function requireTeamWithRedirection({ next, to, router }: RouteContext) {
    const store = useAuthStore()
    const teamStore = useTeamStore()

    if (!store.decodedToken) {
        console.error('No decoded token found')
        return false
    }

    let myTeamId = store.decodedToken.team?.id || ''

    if (!myTeamId) {
        try {
            const { data: teams } = await TeamApi.getMyTeams()

            if (teams?.[0]?.id) {
                myTeamId = teams[0].id
            }

            const { data, meta, status } = teams.length
                ? await TeamApi.switchToTeam(myTeamId)
                : await TeamApi.createNewTeam()

            if (meta) {
                store.updateTokens(meta.jwt.token, meta.jwt.refreshToken)
                if (!myTeamId) {
                    myTeamId = (data as TeamId).id
                }
            }

            if (status === 204) {
                LocalStorage.set(PRO_NEED_CREATE_TEAM, true)
                if (to.name === ProRouteName.ONBOARDING || to.name === ProRouteName.ONBOARDING) {
                    return next()
                } else {
                    router.push({ name: ProRouteName.ONBOARDING, query: { redirectUrl: window.location.href } })
                }
            }
        } catch {
            router.push({ name: ProRouteName.TEAM_ALREADY_EXISTING })
            return false
        }
    }
    if (!teamStore.myTeam) {
        const { data: myTeam } = await TeamApi.getTeam(myTeamId)
        teamStore.$patch({ myTeam })
    }

    return next()
}

export function requiredAuthentification({ next, to }: RouteContext) {
    const spaRunning = import.meta.env.VITE_SPA_RUNNING
    const appUrl = import.meta.env.VITE_APP_BASE_URL
    const store = useAuthStore()
    if (store.isLoggedIn) {
        if (spaRunning === SpaName.LECLUB && !store.hasBusinessLine(BusinessLineName.LECLUB)) {
            window.location.href = appUrl.concat(to.fullPath)
            return false
        }
        return next()
    }

    return store.login(`${window.location.origin}${to.fullPath}`)
}

export function redirectPublicClubPlanIfNotLoggedIn({ next, router }: RouteContext) {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn) {
        return router.push({ name: AboutRouteName.WEWEB_CLUB, hash: '#memberships' })
    }
    return next()
}

export function requiredRoleLeClub({ next }: RouteContext) {
    const store = useAuthStore()
    if (store.hasRole(RoleName.LECLUB)) return next()

    const appUrl = import.meta.env.VITE_APP_BASE_URL
    window.location.href = appUrl

    return false
}

export function requireTeamRole(authorizedTeamRoles: TeamRole[]) {
    return (context: RouteContext) => {
        const { myTeamRole } = useTeamStore()

        const hasAuthorizedRole = myTeamRole ? authorizedTeamRoles.includes(myTeamRole) : false

        if (!hasAuthorizedRole) {
            toast({
                type: ToastType.ERROR,
                title: i18n.global.t('error.access_forbidden.title'),
                text: i18n.global.t('error.access_forbidden.text'),
                duration: 4_000
            })

            console.warn(
                '[MIDDLEWARE] Required one of the roles ' + authorizedTeamRoles.join(', '),
                ', your team role is',
                myTeamRole
            )
            context.router.push(context.from?.name ? context.from : { name: ProRouteName.HOME })
        }

        return context.next()
    }
}

export function requireTeamFeature(requiredTeamFeatures: TeamFeature[]) {
    return (context: RouteContext) => {
        const { myTeamFeatures } = useTeamStore()

        const hasAuthorizedFeature = myTeamFeatures
            ? requiredTeamFeatures.some((requiredFeature) => myTeamFeatures.includes(requiredFeature))
            : false

        if (!hasAuthorizedFeature) {
            toast({
                type: ToastType.ERROR,
                title: i18n.global.t('error.access_forbidden.title'),
                text: i18n.global.t('error.access_forbidden.text'),
                duration: 4_000
            })

            console.warn(
                '[MIDDLEWARE] Required one of the team features ' + requiredTeamFeatures.join(', ') + ' but has '
            )
            return context.next(context.from ? context.from : { name: ProRouteName.HOME })
        }

        context.next()
    }
}

export function requiredApplyPlan({ next, router, to }) {
    if (to.query?.['isBpi'] || to.query?.['isOpeTenYears']) {
        return next()
    }

    if (to.query['inviteCode']) {
        LocalStorage.set(APPLY_INVITE_CODE, to.query['inviteCode'])
    }
    if (!to.query['planKey'] && !to.query['redirect_flow_id']) {
        router.push({ name: RouteName.APPLY_CLUB_PLAN })
        return false
    }
    return next()
}

export function checkAccessApply({ next, router }: RouteContext) {
    const store = useAuthStore()
    if (
        store.hasRole(RoleName.LECLUB) &&
        (!store.hasRole(RoleName.LECLUB_LOST) ||
            !store.hasRole(RoleName.LECLUB_EXPERT_LOST) ||
            !store.hasRole(RoleName.LECLUB_PARTNER_LOST))
    ) {
        router.push({ name: RouteName.ACCOUNT_MEMBERSHIP })
        return false
    }
    return next()
}

export function redirectApplyLost({ next, router, to }: RouteContext) {
    const store = useAuthStore()
    if (store.isLostClub) {
        router.push({ name: RouteName.APPLY_LECLUB_LOST, query: to.query })
        return false
    }
    return next()
}

export async function canAccessAsLost({ next, router }) {
    const authStore = useAuthStore()
    if (authStore.isLostClub) {
        const response = await MemberApi.getClubState()

        if (!response.data.membership?.canApply) {
            router.push({ name: RouteName.ACCOUNT_MEMBERSHIP })
            return false
        }
    }

    return next()
}

export function findContext(to: RouteLocation) {
    if (import.meta.env.VITE_SPA_RUNNING === SpaName.LECLUB) {
        return SpaName.LECLUB
    }
    return to.params.businessLine === BusinessLineName.LECLUB.toLowerCase() ? SpaName.LECLUB : SpaName.LENETWORK
}

export function checkBussinessLineRedirection({ to, next, router }: RouteContext) {
    const spaRunning = import.meta.env.VITE_SPA_RUNNING
    const clubUrl = import.meta.env.VITE_CLUB_BASE_URL
    if (spaRunning === SpaName.LECLUB && to.params.businessLine === BusinessLineName.LECLUB.toLocaleLowerCase()) {
        router.push({
            name: to.name || CompaniesRouteName.COMPANY_HOME,
            params: { ...to.params, businessLine: '' }
        })
    } else if (
        spaRunning === SpaName.LENETWORK &&
        to.params.businessLine === BusinessLineName.LECLUB.toLocaleLowerCase()
    ) {
        window.location.href = clubUrl.concat(
            router.resolve({
                name: to.name || CompaniesRouteName.COMPANY_HOME,
                params: { ...to.params, businessLine: '' }
            }).fullPath
        )
    } else {
        next()
    }
}

export function redirectToPrivate({ to }: RouteContext) {
    const privateHost = import.meta.env.VITE_PRIVATE_BASE_URL
    window.location.href = `${privateHost}${to.path}`
}

/**
 * Permet de retourner un composant autre si le user n'est pas connecté ou s'il n'est pas membre Club
 * Exemple:
 * {
 *      path: '/route',
 *      component: () =>
 *        paywallCheck({
 *           paywall: import('@/views/club/members/MemberPaywallPage.vue'),
 *          default: import('@/views/club/members/profile/MemberProfilePage.vue')
 *     }),
 * }
 * @param params
 * @returns
 */
export function paywallCheck(params: { paywall: ComponentType; default: ComponentType }) {
    const authStore = useAuthStore()
    if (!authStore.isLoggedIn || !authStore.hasRole(RoleName.LECLUB)) {
        return params.paywall
    }
    return params.default
}
